import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from './useAuth';
import Home from './Pages/Home';
import Landing from './Pages/Landing';
import './spinner.css';
import Privacy from './Pages/DisclaimersPages/JavaScript/Privacy';
import Terms from './Pages/DisclaimersPages/JavaScript/Terms';
import Login from './Pages/AuthPages/JavaScript/LogIn';
import Onboarding from './Pages/AuthPages/JavaScript/OnboardingPage';
import ItemDetailsPage from './Pages/DetailItemPages/JavaScript/ItemDetails';
import UserCartPage from './Pages/CheckoutPages/JavaScript/CartPage';
import ProfileSettings from './Pages/ProfilePages/JavaScript/UserProfileSettings';
import PublicBrandProfile from './Pages/ProfilePages/JavaScript/PublicBrandProfile';
import PaymentStatusPage from './Pages/CheckoutPages/JavaScript/PaymentStatusPage';
import SignUp from './Pages/AuthPages/JavaScript/SignUp';
import UserWishlistPage from './Pages/ProfilePages/JavaScript/UserWishlistPage';
import InvitationLandingPage from './Pages/SocialPages/JavaScript/InvitationLandingPage';
import posthog from 'posthog-js';
import PaymentProxy from './components/PaymentProxy'; // Make sure this import statement is correct

function App() {
  const { user, authChecking, onboardingStatus } = useAuth();
  const [isVerifyingToken, setIsVerifyingToken] = useState(false);

  useEffect(() => {
    if (user) {
      posthog.identify(user.uid, {
        email: user.email,
        name: user.displayName,
      });
    }
  }, [user]);

  if (authChecking || onboardingStatus === null) {
    return <div className="spinner"></div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/onboarding" element={
          user ? 
            (onboardingStatus === false ? <Onboarding setIsVerifyingToken={setIsVerifyingToken} /> : <Navigate to="/" replace />)
            : 
            <Navigate to="/" replace />
        } />
        <Route path="/" element={
          user ? 
            (onboardingStatus === false ? <Navigate to="/onboarding" replace /> : <Home />)
            : 
            <Landing />
        } />
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/" replace />} />
        <Route path='/join' element={!user ? <SignUp /> : <Navigate to="/" replace />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/product/:id" element={ user ? <ItemDetailsPage /> : <Navigate to="/" replace />} />
        <Route path="/profile-settings" element={ user ? <ProfileSettings /> : <Navigate to="/" replace />} />
        <Route path="/cart" element={ user ? <UserCartPage /> : <Navigate to="/" replace />} />
        <Route path="/brand/:id" element={ user ? <PublicBrandProfile /> : <Navigate to="/" replace />} />
        <Route path="/payment-status" element={ user ? <PaymentStatusPage /> : <Navigate to="/" replace />} />
        <Route path="/wishlist" element={ user ? <UserWishlistPage /> : <Navigate to="/" replace />} />
        <Route path="/invite" element={<InvitationLandingPage />} />
        <Route path="/payment/:sessionId" element={<PaymentProxy />} />
      </Routes>
    </Router>
  );
}

export default App;